import React from "react"
// import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import data from "../data/quality-policy-data"
import HtmlParser from "react-html-parser"
import { Fragment } from "react/cjs/react.production.min"

const QualityPolitics = props => {
  const path = [{ label: "Inicio", to: "/" }, { label: "Política de calidad" }]
  const renderContent = () => {
    if (!data || !data.length) return

    return data.map((item, index) => {
      return (
        <Fragment key={index}>
          <TitleBlock
            title={item.title}
            className="PrivacyPolicy-subtitle"
            icon=""
          />
          <div className="PrivacyPolicy-text">{HtmlParser(item.content)}</div>
        </Fragment>
      )
    })
  }
  return (
    <div className="LegalNotice">
      <Layout>
        <Hero image="/imgs/quienes-somos.jpg" text="Política de calidad" />
        <div className="container950">
          <Breadcrumb path={path} className="Política de calidad" />
          <TitleBlock
            title="Establecimiento de la política de calidad"
            className="PrivacyPolicy-title"
            icon="fas fa-pen-square"
          />
          <div className="mb-3" style={{ fontWeight: "700", border: "none" }}>
            La Política de Calidad de la institución se establece con la
            definición de su Misión, Visión y Valores, su compromiso de
            cumplimiento con los requisitos legales y reglamentarios aplicables
            y la mejora continua de su Sistema de Gestión de la Calidad.
          </div>
          {renderContent()}
        </div>
      </Layout>
    </div>
  )
}

QualityPolitics.propTypes = {}

export default QualityPolitics
