const data = [
  {
    title: "Misión de EFPA España:",
    content: `<ul class="botones espaciado" style="margin-bottom: 40px">
    <li>Por su afiliación Europea, la promoción en España de los estándares profesionales y éticos y de
      las certificaciones generadas y promovidas por EFPA.</li>
    <li>Agrupar en una organización independiente, contribuyendo a su formación continua para la
      recertificación, a los profesionales cualificados y certificados que se dedican al asesoramiento
      y a la planificación financiera personal, con el objetivo de mejorar sus capacidades, competencias
      y su desempeño profesional.
    </li>
  </ul>`,
  },
  {
    title: "Visión de EFPA España:",
    content: `<ul class="botones espaciado" style="margin-bottom: 40px">
    <li>Que nuestras certificaciones profesionales sean las más reconocidas, demandadas y las de más alta calidad en el sector del asesoramiento y planificación financiera personal, disponibles en España, Europa y en el resto del mundo.</li>
    <li>Asegurar una conducta ética profesional de nuestros asociados y miembros registrados a través del acatamiento del Código Ético de la asociación.</li>
    <li>Convertir el registro de profesionales en EFPA en el referente en cualificación profesional del sector.</li>
    <li>Buscar la excelencia de sus asociados y miembros registrados promoviendo un nivel de conocimientos, por medio de su formación continua, que les permita dar el mejor asesoramiento e información financiera a sus clientes.</li>
  </ul>`,
  },
  {
    title: "Valores de EFPA España:",
    content: `<ul class="botones espaciado" style="margin-bottom: 40px">
    <li>Nuestros asociados y miembros registrados como ejes de referencia de nuestra actividad.</li>
    <li>Formación continuada como motor de innovación.
    </li>
    <li>Ética profesional.</li>
    <li>Transparencia y equidad.</li>
    <li>Que nuestros empleados sientan orgullo de trabajar en una Organización como la nuestra.</li>
  </ul>`,
  },
]

export default data
